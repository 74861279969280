<template>
  <v-container
      :class="ShowMainPage === 'Y' ? 'pa-0 custom-bg' : 'pa-0'"
      fluid
      fill-height>
    <v-container>
      <v-row>
        <v-col cols="12">
          <slot />
        </v-col>
        <v-col cols="12" class="mb-0 pb-0">
          <span class="display-3"><strong>{{ $t('MeeTeeMeeNgern1.CalHeader') }}</strong></span>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-card>
        <v-tabs center-active color="#E90587">
          <v-tab style="text-transform: capitalize">คำนวณวงเงินสินเชื่อ</v-tab>
          <v-tab-item eager>
            <v-row class="pt-3">
              <v-col
                cols="12"
                md="4"
                offset-md="1"
              >
                <v-row row wrap>
                  <v-col cols="12" class="pt-3 pb-0 mb-0" lg="12" v-show="$vuetify.breakpoint.mdAndUp">
                    <span class="display-2">{{ $t('MeeTeeMeeNgern1.GovernmentPrice') }}</span>
                  </v-col>
                  <v-col cols="12" class="pt-3 pb-2 mb-0" lg="12">
                    <v-text-field
                      ref="refGovernmentPrice"
                      dense hide-details 
                      class="bg-editor" 
                      v-model="GovernmentPrice"
                      v-money="moneyPattern1"
                      outlined
                      clearable
                      @click:clear="clearGovernmentPrice()">
                      <template v-slot:label>
                        <span class="input__label text-body-1">เช่น 1,000,000 บาท</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col align="center" cols="6" class="pt-6 pb-2 mb-0" md="6" lg="6">
                    <v-btn
                      :x-small="$vuetify.breakpoint.xs"
                      class="ma-0"
                      outlined
                      color="#E90587"
                      @click="clearGovernmentPrice()"
                    >
                    ล้างข้อมูล
                    </v-btn>
                    <!-- <v-btn dark
                      :x-small="$vuetify.breakpoint.xs"
                      depressed
                      class="ma-0"
                      color="#E90587"
                      outlined
                      @click="clearGovernmentPrice()"
                    >ล้างข้อมูล
                    </v-btn> -->
                  </v-col>
                  <v-col align="center" cols="6" class="pt-6 pb-2 mb-0" md="6" lg="6">
                    <v-btn dark
                      :x-small="$vuetify.breakpoint.xs"
                      depressed
                      class="ma-0"
                      color="#E90587"
                      @click="calGovernmentPrice"
                    >{{ $t('MeeTeeMeeNgern1.calButton') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-card dark
                  color="#E90587"
                  class="d-flex align-center justify-center"  min-height="200px"
                >
                  <v-card-title primary-title class="justify-center">
                    <v-container fill-height fluid>
                      <v-row align="center"
                          justify="center">
                          <v-col align="center" justify="center" cols="12" class="display-3">วงเงินสินเชื่อ</v-col>
                          <v-col align="center" justify="center"  cols="12" class="display-3">
                            {{ GovernmentEstimatedAmount }}<span> บาท</span>
                          </v-col>
                          <v-col align="center" justify="center"  cols="12" class="display-1">(สูตร 70% จากราคาประเมินราชการ)</v-col>
                          <v-text-field
                            style="display: none"
                            hidden
                            :readonly="isReadonly" 
                            clearable
                            @click:clear="clearGovernmentEstimatedAmount()"
                            ref="refGovernmentEstimatedAmount"
                            dense hide-details 
                            class="inputTextRight bg-editor" 
                            v-model="GovernmentEstimatedAmount"
                            v-money="moneyPattern1">
                          </v-text-field>
                      </v-row>
                    </v-container>
                  </v-card-title>
                </v-card>
                หมายเหตุ: การพิจารณาอนุมัติสินเชื่ออาจมีการเปลี่ยนแปลงได้ภายใต้หลักเกณฑ์และเงื่อนไขที่บริษัทกำหนด โปรดตรวจสอบประเภทหลักประกัน และเงื่อนไขอื่นๆ จากเจ้าหน้าที่บริษัทอีกครั้ง

              </v-col>
              <!-- <v-col cols="6" class="pt-10">
                <v-divider></v-divider>
                <span class="caption red--text">*ขึ้นอยู่กับการพิจารณาของบริษัท</span>
                <v-container>
                  <v-row align="center" justify="center">
                    <v-col align="center"
                      cols="12"
                      class="pl-2 pr-0"
                      md="2"
                    >
                      <v-card class="mx-auto" flat outlined color="transparent"
                      >
                        <v-img
                          :src="require(`@/assets/icon-button/link/1.jpg`)"
                          width="50%"
                          height="50%"
                        ></v-img>
                        <v-card-text align="center">
                          <span class="display-1"><a href="https://assessprice.treasury.go.th/" target="_blank">กรมธนารักษ์</a></span>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col align="center"
                      cols="12"
                      class="pl-2 pr-0"
                      md="2"
                    >
                      <v-card class="mx-auto" flat outlined color="transparent"
                      >
                        <v-img
                          :src="require(`@/assets/icon-button/link/2.jpg`)"
                          width="50%"
                          height="50%"
                          
                        ></v-img>
                        <v-card-text align="center">
                          <span class="display-1"><a href="https://www.dol.go.th/phayao/Pages/SmartLandsDownloadApp.aspx" target="_blank">กรมที่ดิน</a></span>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col> -->
            </v-row>
          </v-tab-item>
          <v-tab style="text-transform: capitalize">คำนวณเงินงวดผ่อนชำระ</v-tab>
          <v-tab-item eager>
            <v-row class="pt-3">
              <v-col
                cols="12"
                md="4"
                offset-md="1"
              >
                <v-row row wrap>
                  <v-col cols="12" class="pt-3 pb-0 mb-0" lg="12" v-show="$vuetify.breakpoint.mdAndUp">
                    <span class="display-2">วงเงินกู้</span>
                  </v-col>
                  <v-col cols="12" class="pt-3 pb-2 mb-0" lg="12">
                    <v-text-field
                      ref="refEstimateLoanPrice"
                      dense hide-details 
                      class="bg-editor" 
                      v-model="EstimateLoanPrice"
                      v-money="moneyPattern1"
                      outlined
                      clearable
                      @click:clear="clearEstimateLoanPrice()">
                      <template v-slot:label>
                        <span class="input__label text-body-1">เช่น 1,000,000 บาท</span>
                      </template>
                    </v-text-field>
                    <span>* บุคคลธรรมดา สูงสุดไม่เกิน 10 ล้านบาท<br>* นิติบุคคล สูงสุดไม่เกิน 50 ล้านบาท</span>

                  </v-col>
                  <v-col cols="12" class="pt-1 pb-0 mb-0" lg="12" v-show="$vuetify.breakpoint.mdAndUp">
                    <span class="display-2">ระยะเวลากู้</span>
                  </v-col>
                  <v-col cols="12" class="pt-3 pb-2 mb-0" lg="12">
                    <v-select
                      dense hide-details
                      v-model="EstimateLoanYear"
                      :items="itemsEstimateLoanYear"
                      label="กำหนดที่ 1-5 ปี"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="pt-1 pb-0 mb-0" lg="12" v-show="$vuetify.breakpoint.mdAndUp">
                    <span class="display-2">อัตราดอกเบี้ย (%)</span>
                  </v-col>
                  <v-col cols="12" class="pt-3 pb-2 mb-0" lg="12">
                    <v-text-field
                      ref="refEstimateInterestRate"
                      dense hide-details 
                      class="bg-editor" 
                      v-model="EstimateInterestRate"
                      v-money="moneyPattern2"
                      outlined
                      clearable>
                      <!-- <template v-slot:label>
                        <span class="input__label text-body-1">8.99%</span>
                      </template> -->
                    </v-text-field>
                  </v-col>
                  <v-col align="center" cols="6" class="pt-1 pb-2 mb-0" md="6" lg="6">
                    <v-btn
                      :x-small="$vuetify.breakpoint.xs"
                      class="ma-0"
                      outlined
                      color="#E90587"
                      @click="clearEstimateLoanPrice()"
                    >
                    ล้างข้อมูล
                    </v-btn>
                  </v-col>
                  <v-col align="center" cols="6" class="pt-1 pb-6 mb-0" md="6" lg="6">
                    <v-btn dark
                      :x-small="$vuetify.breakpoint.xs"
                      depressed
                      class="ma-0"
                      color="#E90587"
                      @click="calEstimateLoanPrice"
                    >{{ $t('MeeTeeMeeNgern1.calButton') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-card dark
                  color="#E90587"
                  class="d-flex align-center justify-center"  min-height="200px"
                >
                  <v-card-title primary-title class="justify-center">
                    <v-container fill-height fluid>
                      <v-row align="center"
                          justify="center">
                          <v-col align="center" justify="center" cols="12" class="display-3">ผ่อนชำระ</v-col>
                          <v-col align="center" justify="center"  cols="12" class="display-3">
                            {{ EstimatedInstallments }}<span> บาท/เดือน</span>
                          </v-col>
                          <v-text-field
                            style="display: none"
                            hidden
                            :readonly="isReadonly" 
                            clearable
                            @click:clear="clearEstimatedInstallments()"
                            ref="refEstimatedInstallments"
                            dense hide-details 
                            class="inputTextRight bg-editor" 
                            v-model="EstimatedInstallments"
                            v-money="moneyPattern1">
                          </v-text-field>
                      </v-row>
                    </v-container>
                  </v-card-title>
                </v-card>
                หมายเหตุ: 
                <p>•	การพิจารณาอนุมัติสินเชื่ออาจมีการเปลี่ยนแปลงได้ภายใต้หลักเกณฑ์และเงื่อนไขที่บริษัทกำหนด โปรดตรวจสอบอัตราดอกเบี้ยของแต่ละประเภทผลิตภัณฑ์ ประเภทหลักประกัน และเงื่อนไขอื่นๆ จากเจ้าหน้าที่บริษัทอีกครั้ง</p>
                <!-- <p>•	ผลลัพธ์จะปัดตัวกลมเป็นหลักร้อยบาท ในการคำนวณใช้อัตราดอกเบี้ย MRR ตามประกาศของธนาคารออมสิน บวกเพิ่ม 0.50% เพื่อป้องกัน ผลกระทบต่อระยะเวลาการผ่อนชาระอันเกิดจากการเปลี่ยนแปลงของอัตราดอกเบี้ย MRR</p> -->
              </v-col>
              <!-- <v-col cols="6" class="pt-10">
                <v-divider></v-divider>
                <span class="caption red--text">*ขึ้นอยู่กับการพิจารณาของบริษัท</span>
                <v-container>
                  <v-row align="center" justify="center">
                    <v-col align="center"
                      cols="12"
                      class="pl-2 pr-0"
                      md="2"
                    >
                      <v-card class="mx-auto" flat outlined color="transparent"
                      >
                        <v-img
                          :src="require(`@/assets/icon-button/link/1.jpg`)"
                          width="50%"
                          height="50%"
                        ></v-img>
                        <v-card-text align="center">
                          <span class="display-1"><a href="https://assessprice.treasury.go.th/" target="_blank">กรมธนารักษ์</a></span>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col align="center"
                      cols="12"
                      class="pl-2 pr-0"
                      md="2"
                    >
                      <v-card class="mx-auto" flat outlined color="transparent"
                      >
                        <v-img
                          :src="require(`@/assets/icon-button/link/2.jpg`)"
                          width="50%"
                          height="50%"
                          
                        ></v-img>
                        <v-card-text align="center">
                          <span class="display-1"><a href="https://www.dol.go.th/phayao/Pages/SmartLandsDownloadApp.aspx" target="_blank">กรมที่ดิน</a></span>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col> -->
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-card>
      <v-dialog v-model="dialog.dialogProgress" persistent width="300">
        <v-card color="#E90587" dark>
          <v-card-text>
            <span class="font-weight-bold dark white--text">{{progressMessage}}</span>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
  import {VMoney} from 'v-money'  //  Mask Money
  export default {
    directives: {money: VMoney, money2: VMoney},  //  Mask Money
    components: {
      VMoney
      /* CcLabResult, */
    },
    name: 'HomeAbout',
    props: {
      ShowImg: {
        type: String,
        required: true,
      },
      ShowMainPage: {
        type: String,
        required: false,
      },
    },
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
        appName: process.env.VUE_APP_DB_APP_NAME,
        dialog: {
          dialogPolicy: false,
          dialogProgress: false,
        },
        NameSurName: '',
        PolicyFlag: '',
        subscribeText: '',
        email: '',
        tel: '',
        ipAddress: '',
        GovernmentPrice: 0,
        EstimateLoanPrice: 0,
        EstimateLoanYear: 1,
        EstimateInterestRate: 8.99,
        MarketPrice: 0,
        GovernmentEstimatedAmount: 0,
        EstimatedInstallments: 0,
        MarketEstimatedAmount: 0,
        isReadonly: true,
        moneyPattern1: {  //  Mask Money
          decimal: '.',
          thousands: ',',
          precision: 0,
          masked: false,
        },
        moneyPattern2: {  //  Mask Money
          decimal: '.',
          thousands: ',',
          precision: 2,
          masked: false,
        },
        progressMessage: '',
        itemsEstimateLoanYear: [1, 2, 3, 4, 5],

      }
    },
    methods: {
      async calGovernmentPrice () {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        if (!this.GovernmentPrice || this.GovernmentPrice === 0) {
          this.$swal({type: 'warning', title: this.$t('MeeTeeMeeNgern1.messageGovernment')})
          return
        }
        var price = this.GovernmentPrice
        price = price.replace(/,/g, '')
        await this.axios.post(process.env.VUE_APP_API + '/cal/retrieveCal' + process.env.VUE_APP_DATABASE_TYPE, {
          type: 'g',
          price: price,
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.dialog.dialogProgress = false
              this.GovernmentEstimatedAmount = response.data.data[0].estimated_amount
              var GovernmentEstimatedAmount = response.data.data[0].estimated_amount
              /* if (GovernmentEstimatedAmount.toString().indexOf('.') < 0) { GovernmentEstimatedAmount = GovernmentEstimatedAmount + '.00' } */
              this.$refs.refGovernmentEstimatedAmount.$el.getElementsByTagName('input')[0].value = GovernmentEstimatedAmount
            } else {
              this.dialog.dialogProgress = false
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.dialog.dialogProgress = false
            this.$swal({type: 'error', title: e.message})
          })
      },
      async calEstimateLoanPrice () {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        if (!this.EstimateLoanPrice || this.EstimateLoanPrice === 0) {
          this.$swal({type: 'warning', title: this.$t('MeeTeeMeeNgern1.messageGovernment')})
          return
        }
        var price = this.EstimateLoanPrice
        price = price.replace(/,/g, '')
        var InterestRate = this.EstimateInterestRate
        InterestRate = InterestRate.replace(/,/g, '')
        await this.axios.post(process.env.VUE_APP_API + '/cal/retrieveCalInstallments' + process.env.VUE_APP_DATABASE_TYPE, {
          type: 'g',
          price: price,
          year: this.EstimateLoanYear,
          InterestRate: InterestRate,
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.dialog.dialogProgress = false
              this.EstimateLoanPrice = response.data.data[0].estimated_amount
              var EstimateLoanPrice = response.data.data[0].estimated_amount
              /* if (EstimateLoanPrice.toString().indexOf('.') < 0) { EstimateLoanPrice = EstimateLoanPrice + '.00' } */
              this.$refs.refEstimatedInstallments.$el.getElementsByTagName('input')[0].value = EstimateLoanPrice
            } else {
              this.dialog.dialogProgress = false
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.dialog.dialogProgress = false
            this.$swal({type: 'error', title: e.message})
          })
      },
      async calMarketPrice () {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        if (!this.MarketPrice || this.MarketPrice === 0) {
          this.$swal({type: 'warning', title: this.$t('MeeTeeMeeNgern1.messageMarket')})
          return
        }
        var price = this.MarketPrice
        price = price.replace(/,/g, '')
        await this.axios.post(process.env.VUE_APP_API + '/cal/retrieveCal' + process.env.VUE_APP_DATABASE_TYPE, {
          type: 'm',
          price: price,
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.dialog.dialogProgress = false
              this.MarketEstimatedAmount = response.data.data[0].estimated_amount
              var MarketEstimatedAmount = response.data.data[0].estimated_amount
              /* if (MarketEstimatedAmount.toString().indexOf('.') < 0) { MarketEstimatedAmount = MarketEstimatedAmount + '.00' } */
              this.$refs.refMarketEstimatedAmount.$el.getElementsByTagName('input')[0].value = MarketEstimatedAmount
            } else {
              this.dialog.dialogProgress = false
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.dialog.dialogProgress = false
            this.$swal({type: 'error', title: e.message})
          })
      },
      clearGovernmentPrice () {
        this.$refs.refGovernmentPrice.$el.getElementsByTagName('input')[0].value = 0
        this.GovernmentPrice = 0
        this.clearGovernmentEstimatedAmount()
      },
      clearEstimateLoanPrice () {
        this.$refs.refEstimateLoanPrice.$el.getElementsByTagName('input')[0].value = 0
        this.$refs.refEstimateInterestRate.$el.getElementsByTagName('input')[0].value = 8.99
        this.EstimateLoanPrice = 0
        this.EstimateInterestRate = 8.99
        this.EstimateLoanYear = 1
        this.clearEstimatedInstallments()
      },
      clearGovernmentEstimatedAmount () {
        this.$refs.refGovernmentEstimatedAmount.$el.getElementsByTagName('input')[0].value = 0
        this.GovernmentEstimatedAmount = 0
      },
      clearEstimatedInstallments () {
        this.$refs.refEstimatedInstallments.$el.getElementsByTagName('input')[0].value = 0
        this.EstimatedInstallments = 0
      },
      clearMarketPrice () {
        this.$refs.refMarketPrice.$el.getElementsByTagName('input')[0].value = 0
        this.MarketPrice = 0
      },
      clearMarketEstimatedAmount () {
        this.$refs.refMarketEstimatedAmount.$el.getElementsByTagName('input')[0].value = 0
        this.MarketEstimatedAmount = 0
      },
    }
  }
</script>
<style scoped>
  .custom-bg {
    background: rgb(242, 245, 247) !important;
    /* background: url('../../assets/bg/bg1.jpg');
    background-size: cover; */
  }
  .bg-editor {
    /* `!important` is necessary here because Vuetify overrides this */
    background: rgb(255, 255, 255) !important;
  }
  .input__label {
    color: black;
  }
  .inputTextRight >>> input {
    text-align: right !important;
    padding-right: 12px;
  }
</style>